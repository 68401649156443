jQuery(document).ready(function ($) {
  var mobileWidth = 768,
      $overlay = $('<div class="overlay"></div>'),
      $body = $('body'),
      $navbar = $('.js-navbar'),
      $navbarToggle = $('.js-navbar-toggle');

  $body.append($overlay);

  /*moveNavigation();
  $(window).on('resize', function () {
    (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
  });*/

  $navbarToggle.on('click', function(event) {
    event.preventDefault();

    if ($body.hasClass('nav-is-visible')) {
      closeNav();
    } else {
      openNav();
    }
  });

  $overlay.on('click', function (event) {
    event.preventDefault();
    closeNav();
  });

  function openNav() {
    $body.addClass('nav-is-visible');
    $overlay.addClass('is-visible');
    $navbar.addClass('is-open');
    $navbarToggle.addClass('is-active');
  }

  function closeNav() {
    $body.removeClass('nav-is-visible');
    $overlay.removeClass('is-visible');
    $navbar.removeClass('is-open');
    $navbarToggle.removeClass('is-active');
  }

  /*function isMobile() {
    var e = window,
        a = 'inner';

    if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
    }

    return e[a + 'Width'] <= mobileWidth;
  }*/

  $('.js-scrollto').click(function (e) {
    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
      e.preventDefault();
      var offset = $(this).data('scroll-offset');
      scrollto($(this.hash), offset);
    }
  });

  function scrollto(target, offset) {
    closeNav();
    if (target === null) {
      $('html, body').animate({
        scrollTop: 0
      }, 500);
      return false;
    }

    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
      $('html, body').animate({
        scrollTop: (offset === parseInt(offset, 10)) ? (target.offset().top + offset) : target.offset().top
      }, 500, 'swing');
      return false;
    }
  }
});