// Global module
var eventCalendar = (function ($) {

  var DOM = {};

  var calendarUrl,
      eventsUrl,
      year,
      month;

  function initDom() {
    return {
      '$calendar': $('.js-event-calendar'),
      '$prevMonthButton': $('.js-event-calendar__prev'),
      '$nextMonthButton': $('.js-event-calendar__next'),
      '$calendarDay': $('.js-calendar-day'),
      '$calendarSlider': $('.js-event-calendar-slider')
    };
  }
  function init() {
    DOM = initDom();

    calendarUrl = DOM.$calendar.data('calendar-url');
    eventsUrl = DOM.$calendar.data('calendar-events-url');
    year = (new Date()).getFullYear();
    month = (new Date()).getMonth() + 1;

    DOM.$nextMonthButton.on('click touchstart', function (e) {
      e.preventDefault();
      loadCalendar($(this).data('calendar-date'));
    });

    DOM.$prevMonthButton.on('click touchstart', function (e) {
      e.preventDefault();
      loadCalendar($(this).data('calendar-date'));
    });

    DOM.$calendarDay.on('click touchstart', function (e) {
      e.preventDefault();
      var date = $(this).data('calendar-day__date');
      if (typeof date === 'undefined') {
        date = year + (month < 10 ? '0' + month : month) + parseInt($(this).html(), 10);
      }

      date = parseInt(date, 10);
      DOM.$calendarDay.removeClass('is-active');
      $(this).addClass('is-active');
      loadEvents(date);
    });
  }

  function loadCalendar(date) {
    DOM.$calendar.addClass('is-loading');
    $.ajax({
      url: calendarUrl,
      method: 'GET',
      data: {date: date},
      beforeSend: function () {
        //$btn.prop('disabled', true).addClass('disabled');
      }
    })
      .fail(function (xhr, textStatus, error) {
        DOM.$calendar.removeClass('is-loading');
        alert(error);
      })
      .done(function (data) {
        DOM.$calendar.html(data);
        DOM.$calendar.removeClass('is-loading');
        init();
      });
  }

  function loadEvents(date) {
    console.log('load events(' + eventsUrl + ') for date: ' + date);
    DOM.$calendarSlider.addClass('is-loading');

    $.ajax({
        url: eventsUrl,
        method: 'GET',
        data: {date: date},
        beforeSend: function () {
          //$btn.prop('disabled', true).addClass('disabled');
        }
      })
        .fail(function (xhr, textStatus, error) {
          DOM.$calendarSlider.removeClass('is-loading');
          alert(error);
        })
        .done(function (data) {
          DOM.$calendarSlider.slick('unslick');
          DOM.$calendarSlider.html(data);
          DOM.$calendarSlider.slick({
            dots: true,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false
          });
          DOM.$calendarSlider.removeClass('is-loading');
        });
  }

  init();

  return {
    loadCalendar: function (date) {
      loadCalendar(date);
    }
  };

// Pull in jQuery and Underscore
})(jQuery);
