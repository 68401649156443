jQuery(document).ready(function ($) {
  $('.js-slider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false
  });

  /*$('.js-slider-books').slick({
    dots: true,
    infinite: false,
    arrows: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    //adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });*/

  $('.js-event-calendar-slider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false
  });
});