jQuery(document).ready(function ($) {
  $('.js-event-modal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget); // Button that triggered the modal

    var eventDetails = {
      author: button.data('event-author'),
      title: button.data('event-title'),
      subtitle: button.data('event-subtitle'),
      address: {
        city: button.data('event-city'),
        location: button.data('event-location')
      },
      image: button.data('event-image'),
      date: button.data('event-date'),
      time: button.data('event-time'),
      price: parseInt(button.data('event-price'), 10),
      type: 'event--' + button.data('event-type'),
      is_full: button.data('event-is_full'),
      is_seatmap: button.data('event-is_seatmap'),
      is_buyable: button.data('event-is_buyable'),
      stock_info: button.data('event-stock_info'),
      product_id: button.data('product-id'),
      url: button.data('event-url'),
      url_seatmap: button.data('event-url-seatmap'),
      url_form: button.data('event-url-form')
    };

    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this);
    var $modalContent   = modal.find('.modal-content');
    var $eventCover     = modal.find('.js-event-modal__cover'),
        $eventAuthor    = modal.find('.js-event-modal__author'),
        $eventTitle     = modal.find('.js-event-modal__title'),
        $eventSubtitle  = modal.find('.js-event-modal__subtitle'),
        $eventCity      = modal.find('.js-event-modal__city'),
        $eventLocation  = modal.find('.js-event-modal__location'),
        $eventDate      = modal.find('.js-event-modal__date'),
        $eventTime      = modal.find('.js-event-modal__time'),
        $eventPrice     = modal.find('.js-event-modal__price'),
        $eventCart      = modal.find('.js-event-modal__cart');

    var $footerNormal   = modal.find('.js-event-modal__footer--normal'),
        $footerSeatmap  = modal.find('.js-event-modal__footer--seatmap'),
        $stockInfo      = modal.find('.js-event-modal__footer--stock-info');

    var $qty_add        = modal.find('.js-qty__add'),
      $qty_remove       = modal.find('.js-qty__remove'),
      $qty_value        = modal.find('.js-qty__value'),
      $qty_post_value   = modal.find('.js-qty__post-value'),
      $btn_event_url    = modal.find('.js-event-url'),
      $btn_seatmap_url  = modal.find('.js-event-url-seatmap'),
      CART_ID           = '#nya-cart-' + eventDetails.product_id;

    // ha helyjegyes, akkor nincs darabszám, csak link
    if (eventDetails.is_seatmap) {
      $btn_seatmap_url.attr('href', eventDetails.url_seatmap);
      $footerNormal.addClass('is-hidden').hide();
      $footerSeatmap.removeClass('is-hidden').show();
      $stockInfo.addClass('is-hidden').hide();
    } else { // kosár működéséhez beállítások
      $footerNormal.removeClass('is-hidden').show();
      $footerSeatmap.addClass('is-hidden').hide();
      $stockInfo.addClass('is-hidden').hide();

      if (eventDetails.is_buyable) {
        $eventCart.attr('id', 'nya-cart-' + eventDetails.product_id);
        $eventCart.attr('action', eventDetails.url_form);
        $qty_value.html(1);
        $qty_post_value.val(1);
        $qty_add.removeData('target')
          .data('target', CART_ID)
          .attr('data-target', CART_ID)
          .attr('disabled', false);
        $qty_remove.removeData('target')
          .data('target', CART_ID)
          .attr('data-target', CART_ID)
          .attr('disabled', true);
        $eventPrice.removeData('unit-price')
          .data('unit-price', eventDetails.price)
          .attr('data-unit-price', eventDetails.price);

        var modalCart = nyaAddToCart();
        modalCart.init('nya-cart-' + eventDetails.product_id);
      } else {
        $footerNormal.addClass('is-hidden').hide();
        $stockInfo.removeClass('is-hidden').show();
        $stockInfo.html(eventDetails.stock_info ? eventDetails.stock_info : '');
      }

      if (eventDetails.is_full) {
        $stockInfo.removeClass('is-hidden').show();
        $stockInfo.html(eventDetails.stock_info);
      }
    }

    // esemény adatainak beállítása  DOM-ban
    $eventCover.css('background-image', "url('" + eventDetails.image + "')");
    $eventAuthor.text(eventDetails.author);
    $eventTitle.text(eventDetails.title);
    $eventSubtitle.text(eventDetails.subtitle);
    $eventCity.text(eventDetails.address.city);
    $eventLocation.text(eventDetails.address.location);
    $eventDate.text(eventDetails.date);
    $eventTime.text(eventDetails.time);
    $eventPrice.text(number_format(eventDetails.price, 0, ',', ' '));
    $btn_event_url.attr('href', eventDetails.url);

    // ha van alcím akkor megjelenítjük
    if (eventDetails.subtitle) {
      $eventSubtitle.show();
    } else {
      $eventSubtitle.hide();
    }

    // ha van helyszín infó
    if (eventDetails.address.location) {
      $eventLocation.show();
    } else {
      $eventLocation.hide();
    }

    // téma alkalmazása
    $modalContent.removeClass()
      .addClass('modal-content')
      .addClass(eventDetails.type);
  });
});