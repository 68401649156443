(function(Timer, $) {
  "use strict";

  var _options = {
    clearTicketUrl: '/checkout/clearTicket',
    cartIndexUrl: '/megrendeles',
    interval: 1000,
    timer: 20*60,
    remind: 90,
    timestamp_start: 0,
    timestamp_now: 0,
    template: '<a class="js-timer timer" href="#" rel="tooltip" title="Ennyi időd van"><span class="js-timer__time timer__time">00:00</span></a>',
    container: 'body'
  };

  var _timer = 0,
    _days = 24 * 60 * 60,
    _hours = 60 * 60,
    _minutes = 60,
    _t, _daysLeft, _hoursLeft, _minutesLeft, _secondsLeft;


  Timer.version = '1.1';

  Timer.help = function()
  {
    return "SUGÓ:\r\n\r\nTimer.start() - időzítő indítása\r\n" +
      "Timer.stop() - időzítő leállítása\r\n" +
      "Timer.set(15) - +15 mp-re állítja az időzítőt\r\n" +
      "Timer.set(30).start() - +30 mp-re állítja az időzítőt és el is indítja";
  };

  Timer.set = function(seconds)
  {
    //_options.timer = _options.timer + seconds;
    //_options.timestamp_start = new Date().getTime();
    update();
    return this;
  };

  Timer.start = function(options)
  {
    if (!this.isRunning())
    {
      init(options);
    } else {
      console.log('Timer is already running...');
    }
    //console.log(_options);
    //return this;
  };

  Timer.isRunning = function()
  {
    return _timer !== 0;
  };

  Timer.stop = function()
  {
    if (this.isRunning())
    {
      clearInterval(_timer);
      _t = 0;
      _timer = 0;
      //update();
      $('.js-timer').remove();
    } else {
      console.log('Timer is already stoped!');
    }
    return this;
  };

  Timer.remove = function()
  {
    Timer.stop();
    if (this.getTotalSecondsLeft() <= 0 && _options.timestamp !== 0 && _options.clearTicketUrl !== false)
    {
      //console.log('REMOVE TICKET NOW');
      removeTicket();
    }
  };

  Timer.getTotalSecondsLeft = function()
  {

    return _t;// - Math.floor((now-_options.timestamp) / 1000);
  };

  Timer.getMinutesLeft = function()
  {
    _minutesLeft = Math.floor(_t / _minutes);
    if (_t < 0)
    {
      _minutesLeft = 0;
    }
    if (_minutesLeft <= 9)
    {
      _minutesLeft = '0' + _minutesLeft;
    }

    return _minutesLeft;
  };

  Timer.getSecondsLeft = function()
  {
    _secondsLeft = Math.floor(_t % 60);
    if (_t < 0)
    {
      _secondsLeft = 0;
    }
    if (_secondsLeft <= 9)
    {
      _secondsLeft = '0' + _secondsLeft;
    }

    return _secondsLeft;
  };

  Timer.getTimeLeft = function()
  {
    return this.getMinutesLeft() + ':' + this.getSecondsLeft();
  };

  Timer.getVersion = function()
  {
    console.log('Timer v' + Timer.version);
    return Timer.version;
  };

  function removeTicket()
  {
    window.location.replace(_options.clearTicketUrl);
  }

  function init(options)
  {
    $.extend(_options, options);
    /*if (_options.timestamp == 0)
    {
      _options.timestamp = new Date().getTime();
    }*/
    var $timer = $(_options.template);
    $timer.attr('href', _options.cartIndexUrl);

    var t_start = _options.timestamp_start;
    var t_now = _options.timestamp_now;

    _t = _options.timer - (t_now-t_start);

    //console.log('START: '+t_start);
    //console.log('NOW: '+t_now);
    //console.log('LEFT: '+_t);

    $(_options.container).append($timer);
    update();
    if (Timer.getTotalSecondsLeft() > 0)
    {
      _timer = setInterval(update, _options.interval);
    }
  }

  function update()
  {
    var $timer = $('.js-timer'),
        $time  = $('.js-timer__time');
    $time.html(Timer.getTimeLeft());
    //_options.timer = _options.timer-(_options.interval/1000);
    //_t = Math.floor(_options.timer);

    //_t = Math.floor(t_left);

    //console.log('TICK: '+_t);

    if (_t <= _options.remind)
    {
      $timer.css("background", "red");
    }

    if (_t < 0)
    {
      _t = 0;
      $timer.hide();
      Timer.remove();
    } else {
      $timer.show();
    }
    _t = _t-(_options.interval/1000);
  }
}(window.Timer = window.Timer || {}, jQuery ));