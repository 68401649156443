var nyaCart = (function ($) {
  var cartItem = {
    id: null,
    name: null,
    price: 0,
    qty: 1,
    getTotalPrice: function()
    {
      return Math.abs(this.price) * Math.abs(this.qty);
    }
  };

  var cart = {};

  function getItem(id) {
    if (cart[id] !== undefined) {
      return cart[id];
    }
    return false;
  }

  // Return an object exposed to the public
  return {
    // Add items to our cart
    addItem: function( item ) {
      var _insert = Object.create(cartItem);
      _insert.id = item.id;
      _insert.name = item.name;
      _insert.price = item.price;
      _insert.qty = item.qty;

      if (false === getItem(item.id)) {
        cart[item.id] = _insert;
      } else {
        cart[item.id].qty += Math.abs(item.qty);
      }
    },

    removeItem: function(id) {
      if (false === getItem(id)) {
        return null;
      }
        delete cart.id;
        return true;
    },

    getItems: function() {
      return cart;
    },

    getItem: getItem,

    // Get the count of items in the cart
    getItemCount: function () {
      return Object.keys(cart).length;
    },

    getTotalItemCount: function () {
      var keys = Object.keys(cart);
      var q = this.getItemCount(),
          total = 0;

      while (q--) {
        total += Math.abs(cart[keys[q]].qty);
      }

      return total;
    },

    // Get the total value of items in the cart
    getTotal: function () {
      var keys = Object.keys(cart);
      var q = this.getItemCount(),
          total = 0;

      while (q--) {
        total += cart[keys[q]].getTotalPrice();
      }

      return total;
    }
  };
})(jQuery);

var nyaAddToCart = (function () {
  var DOM = {
    $qty_add: null,
    $qty_remove: null,
    $qty_value: null,
    $qty_post_value: null,
    $price: null
  };

  var unit_price, qty = 1, product_id = 0, limit=null;

  function addItemToCart(e) {
    e.preventDefault();

    limit = parseInt($(this).data('limit'), 10);
    if (limit) {
      if (qty < limit) {
        qty++;
      }
    } else {
      qty++;
    }
    updateAddToCart();
  }

  function removeItemFromCart(e) {
    e.preventDefault();

    if (qty > 0) {
      qty--;
    }
    updateAddToCart();
  }

  function subtotal() {
    return qty * unit_price;
  }

  function updateAddToCart()
  {
    DOM.$qty_value.html(qty);
    DOM.$qty_post_value.val(qty);
    DOM.$price.html(number_format(subtotal(), 0, ',', ' '));

    if (qty === 1) {
      DOM.$qty_remove.attr('disabled', true);
    } else {
      DOM.$qty_remove.attr('disabled', false);
    }

    if (limit > qty) {
      DOM.$qty_add.attr('disabled', false);
    } else {
      DOM.$qty_add.attr('disabled', true);
    }

    /*if (limit === qty) {
      alert('Ebből a termékből egyszerre csak ' + limit + ' darabot vásárolhatsz!');
    }*/
  }

  function initAddToCart(id) {
    if (id === undefined) {
      console.error('Hiba! Nincs megadva a kosár komponens azonosítója');
      return false;
    }

    var $addToCart = $('#' + id);
    if ($addToCart.length === 0) {
      console.error('Hiba a kosár inicializálásakor: nem található a szükséges DOM elem!');
      return false;
    }

    // DOM elemek hozzárendelése
    DOM.$qty_add = $addToCart.find('.js-qty__add');
    DOM.$qty_remove = $addToCart.find('.js-qty__remove');
    DOM.$qty_value = $addToCart.find('.js-qty__value');
    DOM.$qty_post_value = $addToCart.find('.js-qty__post-value');
    DOM.$price = $addToCart.find('.js-add-to-cart__price');

    // változók inicializálása
    product_id = $addToCart.data('product-id');
    unit_price = DOM.$price.data('unit-price');
    qty = Number(DOM.$qty_value.html());

    // Eventek bekötée
    DOM.$qty_add.on('click', addItemToCart);
    DOM.$qty_remove.on('click', removeItemFromCart);

    return true;
  }

  return {
    init: function (id) {
      return initAddToCart(id);
    },
    getProductId: function() {
      return product_id;
    },
    getUnitPrice: function () {
      return unit_price;
    },
    getQty: function () {
      return qty;
    },
    subtotal: subtotal

    //addItem: addItemToCart,
    //removeItem: removeItemFromCart
  };
});

jQuery(document).ready(function ($) {
  var $product_cart = $('.js-product-cart');
  var addToCarts = {};
  $product_cart.each(function() {
    var cart_id = $(this).attr('id');
    addToCarts[cart_id] = nyaAddToCart();
    addToCarts[cart_id].init(cart_id);
  });
});

/**
 * Helper functions
 */
function number_format(number, decimals, dec_point, thousands_sep) {
  // *     example 1: number_format(1234.56);
  // *     returns 1: '1,235'
  // *     example 2: number_format(1234.56, 2, ',', ' ');
  // *     returns 2: '1 234,56'
  // *     example 3: number_format(1234.5678, 2, '.', '');
  // *     returns 3: '1234.57'
  // *     example 4: number_format(67, 2, ',', '.');
  // *     returns 4: '67,00'
  // *     example 5: number_format(1000);
  // *     returns 5: '1,000'
  // *     example 6: number_format(67.311, 2);
  // *     returns 6: '67.31'
  // *     example 7: number_format(1000.55, 1);
  // *     returns 7: '1,000.6'
  // *     example 8: number_format(67000, 5, ',', '.');
  // *     returns 8: '67.000,00000'
  // *     example 9: number_format(0.9, 0);
  // *     returns 9: '1'
  // *    example 10: number_format('1.20', 2);
  // *    returns 10: '1.20'
  // *    example 11: number_format('1.20', 4);
  // *    returns 11: '1.2000'
  // *    example 12: number_format('1.2000', 3);
  // *    returns 12: '1.200'
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}
