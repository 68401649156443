jQuery(document).ready(function ($) {
  let $sticky = $('.js-sticky');
  let offset_pos = parseInt($sticky.data('sticky-offset-top'));

  function sticky() {
    let scroll_top = $(window).scrollTop();

    let offset = typeof offset !== 'undefined' ? offset : 0;
    if (scroll_top >= offset_pos) {
      $sticky.addClass('is-fixed-top');
    } else {
      $sticky.removeClass('is-fixed-top');
    }
  }

  $(window).on("resize scroll", function (e) {
    sticky();
  });
});